.autocomplete-container {
    position: relative;
  }
  .suggestions-container {
    position: absolute;
    /* top: 100%; */
    left: 0;
    width: 100%;
    max-height:120px;
    background-color: #fff;
    /* border: 1px solid #ccc; */
    /* border: 1px solid #ff0000; */
    border-top: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
    overflow: auto;
  }
  .suggestion {
    padding: 10px;
    cursor: pointer;
  }
  .suggestion:hover {
    background-color: #f0f0f0;
  }
  
  .suggestion-highlighted {
    background-color: #f0f0f0;
  }
  .autocomplete-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #DBDBDB;
    border-radius:0.625rem;
    color: #6e6e6e;
    line-height: 1.7;
    font-size: 1rem;
}
.suggestions-container {
  position: absolute;
  top: calc(100% + 10px); /* Adjust spacing from input field */
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other elements */
  max-height: 200px; /* Limit height if needed */
  overflow-y: auto; /* Add scroll if suggestions overflow */
}

.suggestion {
  padding: 10px;
  cursor: pointer;
}

.suggestion:hover {
  background-color: #f0f0f0;
}

.suggestion-highlighted {
  background-color: #f0f0f0;
}

.autocomplete-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #DBDBDB;
  border-radius: 0.625rem;
  color: #6e6e6e;
  line-height: 1.7;
  font-size: 1rem;
}
