@font-face {
  font-family: "Poppins-Regular";
  /*Can be any text*/
  src: local("Poppins-Regular"),
    url("../fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  /*Can be any text*/
  src: local("Poppins-SemiBold"),
    url("../fonts/Poppins-SemiBold.ttf") format("truetype");
}

.height {
  height: 400px;
}

.nav-header {
  width: 20rem;
}

.dlabnav {
  width: 20rem;
}

.content-body {
  margin-left: 20rem;
}

.loader-grow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-grow > .spinner-grow {
  width: 1rem;
  height: 1rem;
  margin: 0px 5px;
}

.btn-login {
  background-color: #e7bc00;
  border-color: #e7bc00;
  color: #ffffff;
  position: relative;
}

.btn-login span {
  margin-left: 5px;
}

.category-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-btn span {
  margin-left: 5px;
}

.btn-login:hover {
  background-color: #c3a009;
  border-color: #e7bc00;
  color: #ffffff;
}

.brand-clv {
  width: 40px !important;
}

.bg-auth {
  background-color: #e7bc00;
}

.text-primary-custom {
  color: #962424 !important;
  font-weight: 700;
  text-decoration: underline;
}

.file-form-label {
  font-size: 12.25px;
  font-weight: bolder;
  color: #777777;
}

.custom-form-check-label {
  font-size: 13px;
}

.form-control[type="file"] {
  padding-left: 10px;
  line-height: 2.4;
}

.back-arrow-container {
  padding-left: 20px;
  margin-top: -20px;
}

.back-arrow-container a i.fa-arrow-left,
.back-arrow-container button i.fa-arrow-left {
  font-size: 25px;
}

.back-arrow-container button {
  border: none;
  background-color: transparent;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.inline-form-group-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.plus-btn {
  width: 46px;
  height: 46px;
  padding: 6px;
  background: transparent;
  color: #962424 !important;
  font-size: 20px;
  border: 1px solid #962424;
  border-radius: 0.625rem;
}

.plus-btn:disabled {
  opacity: 0.6;
  cursor: auto;
}

.form-control-pattan {
  width: 150px;
  margin: 0px 5px;
}

.form-control-Qws-pattan {
  width: 170px;
  margin: 0px 5px;
}

.flex-1 {
  display: flex;
  flex: 1;
}

.content-radio-view {
  display: flex;
  margin-bottom: 5px;
}

.content-radio-view .form-check {
  margin-right: 10px;
}

.content-search-view {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.content-search-view input {
  width: 250px;
}

.content-search-view button {
  border: none;
  background-color: var(--primary);
  padding: 10px 20px;
  color: #fff;
  border-radius: 8px;
  margin-left: 10px;
}

.school-tem-btn-view {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 15px;
}

/******************Custom-input-type***************/
.multi-Image-View {
  height: 105px;
  position: relative;
  padding: 5px;
}

.custom-file-input {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 9;
}

.custom-file-input label {
  color: white;
  background-color: #962424;
  font-family: sans-serif;
  border-radius: 50px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-img-preview {
  display: flex;
  width: 100%;
  overflow: overlay;
}

.img-preview-item {
  position: relative;
}

.img-preview-item img {
  width: 100px;
  height: 60px;
  margin-right: 10px;
}

.img-preview-item button {
  position: absolute;
  top: 15px;
  left: 35px;
  color: white;
  background-color: #962424;
  font-family: sans-serif;
  border-radius: 50px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.img-preview-item p {
  margin: 0px;
  padding-bottom: 14px;
  font-size: 12px;
}

.img-preview-item button i {
  font-size: 16px;
}

.upload-image-wrapper {
  position: relative;
}

.upload-container {
  color: white;
  background-color: #962424;
  font-family: sans-serif;
  border-radius: 50px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 0px;
}

.image-item {
  /* width: 100px; */
  position: relative;
  padding: 0px 5px;
}

.image-item img {
  width: 100px;
  height: 60px;
}

.image-item button {
  position: absolute;
  top: 10px;
  left: 34px;
  background: transparent;
  padding: 5px;
  border-radius: 20px;
  border: none;
}

.image-item button i.fa-regular {
  font-size: 24px;
  color: #962424;
}

.upload-container-view {
  display: flex;
  align-items: center;
}

.image-preview-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-modal-text-view {
  width: 80px;
}

.chra-btn {
  margin-right: 5px;
}

.character-image-view {
  display: flex;
  flex-wrap: wrap;
}

/* .character-image {
    width: 18px;
    height: 70px;
} */

.character-image-item {
  margin: 5px;
  cursor: pointer;
}

.add-Question-list-view {
  display: flex;
  justify-content: flex-end;
  margin: 15px -5px 10px 0px;
}

.filter-form-control {
  width: 100%;
  height: 40px;
  padding: 0px 10px;
  font-size: 12px;
  border-radius: 6px;
}

.filter-form-control-input {
  width: 200px;
  height: 40px;
  padding: 0px 10px;
  font-size: 12px;
  border-radius: 6px;
}

/* .add-Question-list-view button {
    border: none;
    background-color: #962424;

} */
.filter-btn-view button {
  border: none;
}

.basic-details-item {
  margin: 15px 0px;
}

.basic-details-item label {
  font-weight: 600;
  font-size: 14px;
}

.basic-details-item span {
  font-size: 14px;
  padding-left: 10px;
}

.basic-details {
  background: #fff;
  box-shadow: 0px 0px 5px #c3c0c0;
  border-radius: 6px;
  padding: 20px;
}

.select-validator-section {
  display: flex;
  align-items: center;
}

.select-validator-section select {
  width: 100%;
}

.select-validator-section label {
  width: 120px;
}

.validator-btn {
  margin-left: 10px;
  width: 170px;
  height: 45px;
}

.basic-dropdown {
  position: relative;
}

.basic-dropdown .form-control option:disabled {
  background-color: #dcdcdc;
}

.ranking-form-control {
  width: 60px;
  text-align: center;
  height: 30px;
  font-size: 12px;
}

.drop_down_arrow {
  float: right;
  position: absolute;
  top: 14px;
  right: 15px;
  color: #6e6e6e;
  font-size: 11px;
  pointer-events: none;
}

.path_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pdf_left_side {
  width: 95%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.pdf_right_side {
  width: 30px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf_page_input {
  width: 80px;
  padding: 2px 5px;
}

.elibrary_pdf_page_input {
  width: 50px;
  padding: 2px 5px;
  text-align: center;
}

.button_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.content_status {
  padding: 5px;
  align-self: center;
  width: 140px;
  border-radius: 5px;
  text-align: center;
}

.pending_background {
  background-color: #ff6a59;
}

.assian_validator_background {
  background-color: #4cbc9a;
}

.validator_approved_background {
  background-color: #56c760;
}

.assian_designer_background {
  background-color: #4cbc9a;
}

.designer_uploaded_background {
  background-color: #58bad7;
}

.admin_publish_background {
  background-color: #56c760;
}

.fwhite {
  color: #fff;
}

.page-result {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-result p {
  margin: 0px;
}

.add-Question-arrow-position {
  top: 17px;
  right: 15px;
}

.add-question-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loading-view {
  width: 100%;
  height: 100vh;
  background-color: #00000063;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-icon-view {
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-excel-view {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
}

.textarea-container {
  position: relative;
  margin-bottom: 20px;
  margin-top: 5px;
}

.textarea-container .chra-btn {
  position: absolute;
  top: 5px;
  right: 0px;
  color: white;
  background-color: #962424;
  font-family: sans-serif;
  border-radius: 50px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ck-editer-view {
  width: 100%;
  margin-top: 5px;
}

.tab-btn-view {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 5px;
}

.tab-btn-view button {
  width: 100%;
  background-color: #962424;
  padding: 8px 10px;
  border: none;
  color: #fff;
  font-size: 12px;
}

.editor-preview-btn {
  background-color: #962424;
  border-radius: 4px;
  padding: 8px 10px;
  border: none;
  color: #fff;
  font-size: 12px;
}

.radius-lft {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.radius-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.active-editor-btn {
  background-color: #4cbc9a !important;
}

.inactive-editor-btn {
  background-color: #dadada !important;
  color: #6e6e6e !important;
}

.question-submit-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.question-submit-btn-container button {
  flex: 0 1 24%;
  padding: 10px 10px;
}

.question-add-btn-container button {
  flex: 0 1 49%;
}

.text-align-right {
  text-align: right;
}

.add-question-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.add-question-btn-container button {
  flex: 0 0 49%;
}

.date-control-view {
  position: relative;
}

.date-control-view .react-datepicker-wrapper {
  width: 100%;
}

.date-control-view .react-datepicker__input-container input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  background: #fff;
  color: #6e6e6e;
  line-height: 2.4;
  font-size: 1rem;
  border-radius: 0.625rem;
  border: 1px solid #dbdbdb;
  appearance: none;
}

.react-datepicker-popper {
  z-index: 9;
}

.library-dashboard-container {
  width: 100%;
  border-top: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 100px 0px;
}

.library-counter-view {
  width: 300px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  margin: 0px 20px;
  padding: 10px 5px;
}

.clear-date-btn {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  line-height: 0px;
  position: absolute;
  top: 14px;
  right: 40px;
  margin-top: 5px;
}

.clear-date-btn i {
  font-size: 12px;
  color: #ffffff;
}

.hear-btn-content {
  display: flex;
  align-items: center;
}

.hear-btn-content button {
  height: 38px;
  font-size: 12px;
  padding: 0.6875rem 1.219rem;
}

.hear-btn-content .btn-success {
  background-color: #56c760;
}

.hear-btn-content .btn-danger {
  background-color: #962424;
  border-color: #962424;
}

/**************************profile**********************/
.profile-container {
  width: 100%;
}

.profile-header-image {
  width: 120px;
  height: 120px;
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
}

.profile-header-image img {
  width: 120px;
  height: 120px;
  border-radius: 50px !important;
}

.profile-form-content {
  margin-top: 30px;
}

.profile-update-btn {
  background-color: #8b0d0e;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-update-btn:hover {
  background-color: #8b0d0e;
  color: #ffffff !important;
}

.profile-update-btn .spinner-border {
  margin-left: 5px;
  border-width: 3px;
}

.profile-form-content {
  margin-bottom: 20px;
}

.profile-custom-file-input {
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: #8b0d0e;
  border-radius: 50px;
  width: 35px;
  height: 35px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-custom-file-input label {
  margin: 0px;
  cursor: pointer;
}

.profile-custom-file-input label i {
  font-size: 14px;
  color: #ffffff;
}

.picker-btn {
  background-color: transparent !important;
  position: absolute;
  top: 14px;
  right: 10px;
  border: none;
}

.picker-btn i {
  color: #6e6e6e;
  font-size: 16px;
}

.header-profile .dropdown-toggle {
  display: flex;
  flex-direction: column;
}

.header-profile .dropdown-toggle span {
  font-size: 10px;
  padding-top: 2px;
}

.e_library_text {
  text-transform: lowercase;
}

.duplicate_questions_icon i {
  font-size: 20px;
  padding-right: 5px;
}

.assign-question-top-view {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.assign-question-top-view .basic-dropdown {
  width: 210px;
  margin-right: 5px;
}

/* .tab-view {
    border: 1px solid #DBDBDB;
} */

.tab-btn-view {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* border-bottom: 1px solid #DBDBDB; */
}

.tab-btn-view button {
  width: 200px;
  height: 50px;
  background-color: transparent;
  color: #777777;
  border: 1px solid #dbdbdb;
  border-bottom: none;
}

.tab-content-view {
  border: 1px solid #dbdbdb;
  border-bottom: none;
  /* padding-left: 10px; */
}

.tab-scholastic-btn {
  border-top-left-radius: 10px;
}

.tab-competitive-btn {
  border-top-right-radius: 10px;
}

.active-tab {
  background-color: #962424 !important;
  color: #ffffff !important;
  border-color: #962424 !important;
}

.tab-content-view table {
  margin-bottom: 0px;
}

.tab-content-view table th {
  text-align: center;
}

.tab-content-view table td {
  text-align: center;
}

.otp-form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.otp-form-group .form-control {
  width: 50px;
  text-align: center;
}

.resend-otp {
  text-align: center;
}

.resend-otp button {
  color: #8b0d0e;
  font-weight: 700;
  border: none;
  background-color: transparent;
}

.resend-otp button:disabled {
  opacity: 0.6;
}

.resend-otp span {
  padding-left: 5px;
  font-weight: 700;
}

/***************************custom-switch-button*************/

.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 20px;
  margin-bottom: 0px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #8b0d0e;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.subscribtion-switch-button-view {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  flex-wrap: wrap;
}

.subscribtion-switch-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 20px;
}

.switch-label {
  margin-bottom: 0px;
  padding-right: 10px;
}

.subscribtion-dublicate-library {
  display: flex;
  align-items: center;
}

.subscribtion-dublicate-library-container {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #dbdbdb;
}

.subscribtion-dublicate-library .basic-dropdown {
  width: 100%;
  margin-right: 10px;
}

.event-image-view img {
  width: 100px;
  margin-bottom: 20px;
}

.td-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 400px;
}

.subject-group-view {
  width: 100%;
  min-height: 100px;
  border: 1px solid #dbdbdb;
  border-radius: 0.625rem;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-subject-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.form-subject-group .form-check-label {
  margin-top: 1px;
  margin-right: 20px;
}

.add-multiple-subject {
  display: flex;
}

.add-com-subject-details-container {
  margin-bottom: 20px;
}

.add-com-subject-details {
  border-radius: 0.625rem;
  border: 1px solid #dbdbdb;
  padding: 20px;
  margin-bottom: 15px;
}

.question-form-group-view {
  display: flex;
}

.type-question-label {
  flex: 1;
}

.number-question-label {
  width: 200px;
}

.question-modal-form {
  padding: 15px 25px 25px 25px;
}

.add-filter-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.add-Question-status-arrow-position {
  top: 14px !important;
}

.clear-question-type {
  position: relative;
}

.clear-question-type button {
  background-color: #8b0d0e;
  top: 12px;
  right: 15px;
  border: none;
}

.reset-filtter-value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.subject-color-box {
  width: 50px;
  height: 20px;
  border-radius: 3px;
}

.school-name {
  width: 200px;
  white-space: normal !important;
}

.school-address {
  width: 250px;
  white-space: normal !important;
}

.school-number {
  width: 150px;
  white-space: normal !important;
}

/********************Media-screen*********************/

@media only screen and (max-width: 87.5rem) {
  .nav-control {
    right: -4.556rem;
  }

  [data-sidebar-style="full"] .header,
  [data-sidebar-style="overlay"] .header {
    padding-left: 18rem;
  }
}

/* *********SPINNER********************* */

.screec_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex: 1;
  z-index: 999;
}

.screec_container:after {
  content: "";
  background-color: #000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  opacity: 0.2;
}

.loader-spinner-container {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.spinner-view {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-btn-view {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.reset-btn-view-bottom-alignment {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.chapter-list-view {
  padding: 0px 10px 10px 10px;
}

.subject-img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.subject-img-form {
  width: 70px;
  height: 70px;
  border-radius: 10px;
}

/*********************react-pagination******************/
.pagination.pagination-sm .page-item .page-link {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
}

/* ======PDF======== */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fullscreen {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  right: 0;
  z-index: 100;
  background-color: #fff;
}

.pdf_fullScreen {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.top_back_container_pdf {
  width: 100px;
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  color: #2d6179;
  font-weight: 600;
  cursor: pointer;
}

.back_arrow {
  color: #2d6179;
  font-size: 18px;
  margin-right: 5px;
  font-weight: bold;
}

.pdf_right_side_container {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  color: #2d6179;
}

.cursorPointer {
  cursor: pointer;
}

.cursor_context_menu {
  cursor: context-menu;
}

.text_blue {
  color: #245c75;
}

.icon_description {
  margin-left: 5px;
}

.modal-editor .tox-toolbar__primary {
  display: none !important;
}

.modal-editor .tox .tox-statusbar {
  display: none;
}

.create_history .react-datepicker-popper {
  z-index: 9999 !important;
}

.no_data {
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
}

/* database backup table */
.custom-table {
  border-radius: 5px;
}

/* feedback table */
.wide-cell {
  width: 700px;
  background-color: red;
}

/* library  filter container */
.filter-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/* class filter of library */
.filter-class-library {
  margin-top: 10px;
}

.permission-list-view {
  width: 100%;
  min-height: 300px;
  padding: 20px 40px;
}

.main-form-check-view:last-child {
  border: none;
}

.main-form-check-view {
  border-bottom: 1px solid #dbdbdb;
  margin: 20px 0px;
}

.sub-form-check-view {
  margin: 0px 0px 10px 20px;
}

.sub-form-check-view > .form-check {
  font-weight: 400;
  margin: 10px 0px;
}

.permission-submenu {
  display: flex;
  background-color: #fff1f1;
  padding: 10px;
  border-radius: 5px;
}

.permission-submenu .form-check-label {
  margin-bottom: 0px;
}

/* Mis_Reg_User_Filter */
.filtersAlign {
  display: flex;
  flex-wrap: wrap;
  padding-left: 2rem;
  justify-content: flex-end;
}

/* custome dropdown arrow align */
.custome_dropdown_arrow_align {
  right: 25px;
}

/* custome_dropdown_width */
.custome_dropdown_width {
  width: 100px;
}

/* subscription details page total count start */
.subscription-details {
  background-color: #f5f5f5;
  padding: 10px;
  padding-right: 25px;
  padding-left: 25px;
  border-radius: 5px;
}

.subscription-info {
  margin-bottom: 5px;
}

.subscription-info strong {
  display: inline-block;
  margin-right: 5px;
}

.subscription-info .count {
  font-weight: bold;
  color: #333;
}

.subscription-info .amount {
  font-weight: bold;
  color: #007bff;
}

/* subscription details page total count end */

/* admin dashboard panel box desgin start */
.info-box {
  height: 150px;
  border-radius: 5px;
  padding: 20px;
  color: #fff;
  text-align: center;
}

.info-box-1 {
  background-color: #3498db;
}

.info-box-2 {
  background-color: #2ecc71;
}

.info-box-3 {
  background-color: #e74c3c;
}

.info-box-4 {
  background-color: #f39c12;
}

.info-box h5 {
  color: #fff;
}

.info-box p {
  font-size: 36px;
  font-weight: bold;
  margin-top: 20px;
}

/* admin dashboard panel box desgin end */

/* Mis dashboard filter select field start */
.MisFilter {
  width: fit-content;
  margin-bottom: 20px;
}

/* Mis dashboard filter select field end */

.menu_permission_sub_container {
  padding: 20px;
  border-radius: 5px;
  background-color: #630e0e0d;
  display: flex;
  flex-wrap: wrap;
}

.RoleDescription {
  padding-bottom: 20px;
  padding-top: 30px;
}

.fromContainer {
  padding-left: 100px;
  padding-right: 100px;
}

.submitButtonRole {
  padding-right: 50px;
  padding-left: 50px;
}

.colorPaddingSubFrom {
  background-color: white;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

/* datepicker month-year select with scrollbar start */
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  overflow-y: auto;
  max-height: 150px;
}

/* datepicker month-year select with scrollbar end */

.css-13cymwt-control {
  border: 1px solid #dbdbdb !important;
  padding: 5px !important;
  border-radius: 9px !important;
  font-weight: 400 !important;
  color: #6e6e6e !important;
  /* line-height: 2.4; */
  font-size: 16px !important;
  border-radius: 0.625rem !important;
}

.css-13cymwt-control:hover {
  border: 1px solid #dbdbdb !important;
  padding: 5px !important;
}

.approve_status {
  padding: 3px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
}

.reject_background {
  background-color: #ff0000;
}

.pending_background {
  background-color: #ff6600;
}

.approved_background {
  background-color: #33cc00;
}

.two_column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
}

.one_column {
  display: flex;
  flex: 1;
  /* border:1px solid red; */
}

.basic-view-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column-item {
  margin-bottom: 20px;
}

.question-column-item-value h4 {
  padding-left: 10px;
  margin-bottom: 10px;
}

.question-column-item-value p {
  margin-bottom: 2px;
}

.question-column-item {
  display: flex;
}

.item_disable {
  opacity: 0.6;
  pointer-events: none;
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
}

.icon_right {
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  height: 65%;
}

.reject_list_container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #cdcdcd;
  padding-bottom: 10px;
  padding-top: 10px;
  /* min-height: 200px;
  max-height: 400px;
  overflow: auto; */
}

.reject_list_parent_container {
  min-height: 200px;
  max-height: 400px;
  overflow-y: scroll;
}

.name_icon {
  width: 50px;
  height: 50px;
  border-radius: 60px;
  background-color: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.reject_details_container {
  width: 350px;
  /* border:1px solid red; */
}

.reject_name_container {
  font-size: 14px;
  font-weight: 600;
}

.reject_reason_container {
  font-size: 12px;
}

.name_text {
  font-size: 18px;
  color: #000;
}

.accept_reject_button_container {
  display: flex;
  flex: 1;
  /* border:1px solid red; */
  justify-content: space-around;
  align-items: center;
}

.accept_reject_btn {
  width: 150px;
  padding: 10px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.accept_btn_background {
  background-color: #1fb51f;
}
.reject_btn_background {
  background-color: #fe3737;
}
.preview_btn_background{
  background-color: #962424;
}
.published_background {
  background-color: #66cccc;
}

.chapter_col {
  white-space: pre-wrap;
}

.position-relative {
  position: relative;
}

.history_background {
  background-color: #8389e0;
}

.password-form-view {
  position: relative;
}

.password-form-view button {
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  height: 45px;
  width: 45px;
  background: transparent;
  font-size: 15px;
  color: #dbdbdb;
}

.form-control::placeholder {
  color: #e4e4e4;
}

.col_heading {
  font-size: 14px !important;
  /* border:1px solid red; */
  font-family: "Arial Narrow";
}

.col_table_body tr td {
  font-size: 10px !important;
}

.notification_parent {
  position: relative;
}

.notification_counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: #ff0000;
  border-radius: 30px;
  color: #fff;
  font-size: 9px;
  font-weight: 600;
  position: absolute;
  top: 5px;
  right: 5px;
}

.notification_dropdown .dropdown-menu-end .widget-media,
.notification_dropdown .dropdown-menu-end .widget-timeline {
  height: calc(100% - 1.2rem) !important;
}

.no_notification_continer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height:100%; */
}

.no_notification {
  color: #ff0000;
}

.studentDetailsContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.imagStudentDetailsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 2px solid #0d629b;
}

.rowLableContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
}

.leftLabel {
  width: 140px;
  /* border:1px solid red; */
  margin-left: 100px;
}

.middleLable {
  width: 20px;
  /* border:1px solid red; */
}

.rightData {
  width: 250px;
  /* border:1px solid red; */
}

.studentLable {
  font-size: 14px;
  font-weight: bold;
}

.studentLableData {
  font-size: 14px;
}

.imageContaineNnameText {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 2px solid #0d629b;
  font-size: 60px;
  font-weight: bold;
  color: #fff;
  background-color: #3498db;
}

.successBox {
  text-align: center;
  padding: 5px;
  border: 2px solid green;
  border-radius: 20px;
  color: green;
  font-weight: bold;
  cursor: pointer;
}

.resung_successBox {
  text-align: center;
  padding: 5px;
  border: 2px solid green;
  border-radius: 20px;
  color: green;
  font-weight: bold;
}

.unSuccessBox {
  text-align: center;
  padding: 5px;
  border: 2px solid red;
  border-radius: 20px;
  color: red;
  font-weight: bold;
}

.refundBox {
  text-align: center;
  padding: 5px;
  border: 2px solid rgb(23, 60, 223);
  border-radius: 20px;
  background-color: rgb(59, 91, 231);
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

.linkColor {
  color: #962424;
}

.scholastic-section-title label {
  font-size: 14px;
  font-weight: 600;
}

.scholastic-section {
  width: 100%;
  border: 2px solid #dbdbdb;
  border-radius: 10px;
  padding: 20px;
}

.competitive-section-title {
  margin-top: 20px;
}

.competitive-section-title label {
  font-size: 14px;
  font-weight: 600;
}

.competitive-section {
  position: relative;
  width: 100%;
  border: 2px solid #dbdbdb;
  border-radius: 10px;
  padding: 20px;
}

.competitive-section .plus-btn {
  position: absolute;
  top: 45%;
  right: -70px;
}

.scale-up-switch {
  transform: scaleX(1.5) scaleY(1.3);
  display: flex;
  justify-content: center;
}

.generator-details-container {
  margin: 20px 10px 0px 20px;
}

.generator-details-container label {
  font-size: 14px;
  display: block;
  margin-bottom: 15px;
}

.confirm-button-class {
  background-color: #4cbc9a;
}

.align {
  height: 40px;
}

.align::placeholder {
  color: #1b1a1b;
  opacity: 0.7;
}

.form-control::placeholder {
  color: #333333;
  opacity: 0.6;
}

.clear-date-btn-align {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  line-height: 0px;
  position: absolute;
  top: 8px;
  right: 40px;
  margin-top: 5px;
}

[data-sidebar-style="full"][data-layout="vertical"]
  .dlabnav
  .metismenu
  > li
  > a {
  font-size: 0.95rem;
}

.image-fileView {
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  position: relative;
  height: 100px;
}

.upload-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.image-file {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;
}

.image-file-label {
  align-items: center;
  color: #6e6e6e;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  height: 100px;
  justify-content: center;
  width: 100%;
}

.image-file-label i.fa {
  font-size: 26px;
}

.uploadFile-view {
  width: 100%;
  height: 100%;
  background-color: #33333370;
  position: absolute;
  top: 0px;
  left: 0px;
}

.btn-info-color {
  background-color: #58bad7 !important;
  border-color: #58bad7 !important;
}

.upload-image-modal {
  width: 100%;
  height: 100px;
}

.filter-group-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.session-filter-container {
  flex: 1;
}

.session-filter-input {
  height: 40px;
  border-radius: 6px;
  font-size: 12px;
  color: #6e6e6e !important;
}

.session-date-control .react-datepicker__input-container input {
  height: 40px;
  border-radius: 6px;
  font-size: 12px;
}

.session-filter-btn {
  height: 40px;
  border-radius: 6px;
}

.sub-preview-btn {
  position: absolute;
  top: 0px;
  right: -100px;
  background-color: #962424;
  color: #ffffff;
}

/* ////  Preview-Details  */
.assessment_container_top {
  display: flex;
  flex: 1;
}

.assessment_question_number {
  padding-bottom: 10px;
}

.assessment_question_number h3 {
  font-size: 16px;
  color: #000000;
}

.assessment_container_bottom {
  display: flex;
  flex: 1;
  /* flex-direction: row; */
  flex-direction: column;
}

.assessment_container_bottom_left,
.assessment_container_bottom_right {
  display: flex;
  flex: 1;
  /* max-width: 512px; */
}

.assessment_container_bottom_left {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.question_details {
  padding: 10px 0;
  font-weight: 900;
}

.answer_details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 900;
}

.assessment_container_bottom_right {
  flex-direction: column;
}

.bold_heading {
  font-weight: 900;
}

.font_green {
  color: #56c760;
  font-weight: 500;
}

.helper-text {
  padding-right: 8px;
  font-family: "math-regular" !important;
  font-size: 18px !important;
  /* color: #245c75; */
  color: #101010;
}

.helper-text div p span {
  font-family: "math-regular" !important;
  font-size: 18px !important;
  color: #101010 !important;
  font-weight: 900 !important;
}

.wrap_text {
  width: 100%;
  /* overflow: hidden; */
  overflow-x: auto;
  overflow-y: hidden;
  word-wrap: break-word;
}

.wrap_text img {
  width: 100%;
  height: auto;
}

.assessLeftPanelWidth {
  width: 130px;
  /* border:1px solid red; */
  flex: 0 0 130px;
}

.bottom_border {
  border: 1px solid #d3d3d3;
}

.option_container {
  /* display: flex;
  flex-direction: row;
  flex: 1; */
  /* justify-content: space-around; */
  justify-content: flex-start;
  margin: 10px 0;
}

.display_flex {
  display: flex;
}

.same_area {
  display: flex;
  flex: 1;
}

.assessment_reasons mjx-mspace {
  display: block;
  height: 10px;
}

.assessment_reasons mjx-assistive-mml {
  width: 1px;
  height: 1px;
}

.help-text-view p {
  color: #1b1a1b;
}

.assign-container {
  margin-top: 10px;
}

.assign-btn-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.assign-question-btn-group {
  display: flex;
  align-items: center;
}

.table-scrollable-view {
  height: 300px;
  overflow: auto;
}

.question-btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.question-btn-group button {
  width: 200px;
  margin: 0px 10px;
}

.preview-modal-btn-view {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-modal-btn-view button {
  width: 140px;
  margin: 0px 10px;
}

.content_disable {
  opacity: 0.6;
  pointer-events: none;
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
}

.student_list .date-control-view .react-datepicker__input-container input {
  font-size: 12px !important;
}

.clear-question-type-new {
  position: relative;
}

.clear-question-type-new .btn_class {
  background-color: #8b0d0e;
  top: 12px;
  right: 10px;
  border: none;
}

.school_list .drop_down_arrow {
  float: right;
  position: absolute;
  top: 14px;
  right: 50px;
  color: #6e6e6e;
  font-size: 11px;
  pointer-events: none;
}

.margin_top {
  margin-top: 0 !important;
}

.spinner-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-btn span {
  margin-left: 5px;
}

.height_40 {
  height: 40px;
}

.border_radius_6 {
  border-radius: 6px;
}

.font_size_12 {
  font-size: 12px;
}

/* Dashboard */
.small_card {
  width: 230px;
  height: 120px;
  background-color: #f8f8f8;
}

.welcome_text {
  font-family: "Poppins-SemiBold";
  font-size: 20px;
  color: #000;
}

.btn_size {
  width: 80px;
  height: 40px;
  /* border: 1px solid red */
}

.blue_border {
  border: 2px solid #0c63ff;
  display: flex;
  /* flex: 1; */
}

.font_color_blue {
  color: #0c63ff;
}

.font_color_black {
  color: #000;
}

.filter_background {
  background-color: #7ae47440;
  box-shadow: "1px 3px 1px #9E9E9E";
}

.export_background {
  background-color: #fff;
}

.image_container {
  width: 18px;
  height: 18px;
  justify-content: "center";
  align-items: "center";
}

.image_style {
  width: 100%;
  height: 100%;
}

.button_text {
  font-family: "Poppins-Regular";
  font-weight: 600;
}

.card_icon_container {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  /* background-color: #ff0000; */
  border-radius: 40px;
  margin-right: 10px;
}

.icon_image_style {
  width: 50%;
  height: 50%;
}

.card_heading {
  font-family: "Poppins-Regular";
  font-weight: 400;
  font-size: 14px;
  color: #00000060;
}

.card_counter {
  font-family: "Poppins-SemiBold";
  font-weight: 600;
  font-size: 24px;
  color: #000000;
  height: 80px;
  /* border:1px solid red */
}

.card_view_all {
  font-family: "Poppins-Regular";
  font-size: 12px;
}

.right_box_width {
  width: 150px;
}

.fill_color {
  color: #ff0000;
}

.arrow_size {
  width: 20px;
}

.arrow_icon_size {
  font-size: 14px;
}

.registration_container {
  display: flex;
  width: 230px;
  background-color: #f8f8f8;
  height: 320px;
}

.registration_analytics_container {
  display: flex;
  flex: 0.97;
  /* height: 100px; */
  background-color: #f8f8f8;
  height: 320px;
}

.registration_heading {
  font-family: "Poppins-SemiBold";
  font-size: 20px;
  color: #000;
  margin-right: 5px;
}

.day_text {
  font-family: "Poppins-Regular";
  font-size: 9px;
  color: #2c2c2c;
  position: relative;
  top: 3px;
}

.table_heading_text {
  font-family: "Poppins-Regular";
  font-size: 11px;
  color: #2c2c2c;
}

.bottom_line_table_heading {
  border-bottom: 1px solid #00000060;
  height: 35px;
}

.width_flex_1 {
  display: flex;
  flex: 0.7;
}

.width_flex_half {
  display: flex;
  flex: 0.3;
}

.width_board {
  width: 50px;
}

.width_user {
  width: 60px;
}
.width_user_110 {
  width: 110px;
}

.width_registration {
  width: 100px;
}

.dashboard_card_height {
  min-height: 800px;
}

.dorpdown_container {
  width: 120px;
  /* height: 105px; */
  background-color: #eeeeee;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
}

.dorpdown_container_single_item_height {
  height: 25px;
}

.dorpdown_container_all_item_height {
  height: 105px;
}

.registration_analytics_top_container {
  height: 130px;
}

.dropdown_font {
  font-family: "Poppins-Regular";
  font-size: 11px;
  color: #2c2c2c;
}

.dropdown_circle {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 100px;
  border: 1px solid #535353;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown_circle_fill {
  width: 6px;
  height: 6px;
  background-color: #5288f2;
  border-radius: 100px;
}

.select_value {
  margin-bottom: 10px;
  padding-top: 2px;
}

.dropdown_container_absolute_position {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}

.border_red {
  border: 1px solid red;
}

.view_text_position {
  position: absolute;
  bottom: 30px;
  right: 15px;
  opacity: 0.99;
}

.no_dataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 230px;
  width: 100%;
}

.color_red {
  border: 1px solid red;
}

.last_seven_days_container {
  max-height: 230px;
  overflow: auto;
}

.zIndex100 {
  z-index: auto;
}

.opacity_ninty_nine {
  opacity: 0.99;
}

/* mis Dashboard */
.mis_dashboard_box {
  width: 200px;
  height: 100px;
  border-radius: 10px;
  border: 2px solid #000;
}

.height_as_per_screen {
  height: 100vh;
}

.user_details_modal_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.box_width {
  flex: 1;
  height: 20px;
  text-align: start;
  margin-bottom: 10px;
}

.leftBox_width {
  width: 120px;
  height: 20px;
  text-align: start;
  margin-bottom: 10px;
  font-weight: bold;
}

.footer_text {
  width: 100%;
  text-align: center;
}

.table_th_font {
  text-align: center;
  /* width: 120px; */
  white-space: pre-wrap;
  /* font-size: 10px; */
}

.date_picker_box_height {
  height: 46px;
  border-radius: 6px;
}

#range-slider-ab .range-slider__thumb {
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

#range-slider-ab {
  height: 2px;
  background: #cdcfe9;
}

.student_details_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 10px;
}

.student_details_child_container {
  width: 50%;
}

.label_container {
  width: 150px;
}

.modal_label_container {
  width: 140px;
}

.performance_details_contaner {
  display: flex;
  flex-direction: column;
  margin: 10px 20px 20px 20px;
}

.individual_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  margin-bottom: 5px;
}

.first_container {
  width: 180px;
}

.second_container {
  width: 10px;
}

.third_container {
  flex: 1;
}

.rating_left_container {
  display: flex;
  flex: 0.6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(5, 106, 201);
  height: 200px;
  padding-left: 20px;
  padding-right: 20px;
}

.rating_right_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(5, 106, 201);
  height: 200px;
  padding-left: 20px;
  padding-right: 20px;
}

.rating_heading {
  font-size: 20px;
}

.rating_star_container {
  width: 130px;
  height: 35px;
  background-color: #000;
  margin-top: 10px;
  margin-bottom: 10px;
}

.rating_counter {
  font-size: 14px;
}

.progress_bar_container {
  display: flex;
  height: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #000;
  width: 100%;
  padding-left: 5px;
  /* padding-right: 5px; */
}

.star_left_container {
  width: 35px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.star_middle_container {
  width: 165px;
  margin-left: 5px;
  margin-right: 5px;
}

.star_right_container {
  /* width:70px; */
  font-size: 12px;
}

.link_color {
  text-decoration: underline;
  color: #007bff;
}

.start_number_container {
  width: 12px;
  /* border: 1px solid red; */
}

.link_color {
  text-decoration: underline;
  color: #007bff;
}

.reasone-alert span {
  font-size: 13px;
}

.reasone-alert span i.fa {
  font-size: 18px;
  color: #f1cb39;
}

.student_subscription_container {
  /* min-height: 200px; */
  max-height: 400px;
  overflow: auto;
}

.dropDownZindex {
  z-index: 1;
}

.approved-content-text {
  margin: 0px;
}

.approved-content-filter-control .react-datepicker__input-container input {
  height: 40px;
  border-radius: 6px;
}

.pay-btn {
  width: 70px;
  padding: 5px 0px;
}

.tbody-scroll {
  display: block;
  max-height: 300px;
  overflow-y: auto;
}

.tbody-scroll tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.thead-fix tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
} */
.excel-copyright {
  border: none;
  background: none;
}

/* Mis Dashboard */

/* Dashboard */
.mis_small_card {
  width: 313px;
  height: 70px;
  /* background-color: #F6FCFF; */
  box-shadow: 0 3px 0 0 #25170e15;
}

.mis_parent_container {
  display: flex;
  flex: 1;
  /* border:1px solid #000 */
}

.mis_card_left_container {
  display: flex;
  flex: 2;
  /* border:1px solid red */
}

.mis_card_right_container {
  display: flex;
  flex: 1;
  font-family: "Poppins-Regular";
  font-size: 12px;
  color: #000;
  /* border:1px solid green; */
  height: 20px;
}

.mis_card_heading {
  font-family: "Poppins-Regular";
  font-size: 12px;
  color: #000;
}

.mis_card_count {
  font-family: "Poppins-SemiBold";
  font-size: 20px;
  color: #000;
}

.mis_card_right_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.mis_registration_container {
  display: flex;
  width: 230px;
  background-color: #f1f7ff;
  height: 320px;
  box-shadow: 0 3px 0 0 #25170e15;
}

.mis_registration_analytics_container {
  display: flex;
  flex: 0.97;
  /* height: 100px; */
  background-color: #f1f7ff;
  height: 320px;
  box-shadow: 0 3px 0 0 #25170e15;
}

.mis_dorpdown_container {
  width: 120px;
  /* height: 105px; */
  background-color: #f1feff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
}

.mis_width_registration {
  /* width: 110px; */
  display: flex;
  flex: 1;
}

.mis_width_board {
  /* width: 50px; */
  display: flex;
  flex: 1;
}

.mis_width_user {
  /* width: 60px; */
  display: flex;
  flex: 1;
}
.right_parent_container {
  /* background-color: #ff0000; */
}

/* End Mis Dashboard */
.cursorNone {
  cursor: auto;
}
.notification-seemore-btn-view {
  display: flex;
  justify-content: flex-end;
}
.see-more-btn {
  background: none;
  border: none;
  color: #962424;
  text-transform: capitalize;
  text-decoration: underline;
}
.tab-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.no-permission {
  display: flex;
  justify-content: center;
  align-items: center;
  height:400px;
  color:#ff0000;
  font-size: 20px;
  text-align: center;
}
.padding-right-32 {
  padding-right: 32px;
}

.user-name span{ 
  white-space: break-spaces
}

.ad-info-text{
  font-family: "Poppins-Regular";
  font-size: 10px;
  margin-bottom:0;
}

